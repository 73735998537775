export const KEY_BACKSPACE = 8;
export const KEY_DELETE = 46;
export const KEY_F2 = 113;
export const KEY_ENTER = 13;
export const KEY_TAB = 9;
export const KEY_PASTE = 86;

export enum AgGridEventKey {
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  F2 = 'F2'
}
