export const isWithinRange = (value: number, min: number, max: number) =>
  value >= min && value <= max;

export const truncateFloat = (
  value: number,
  maxDigitsAfterDecimalPoint: number
) => Number.parseFloat(value.toFixed(maxDigitsAfterDecimalPoint));

export const calculatePercentage = (percentage: number, value: number) => {
  return value * (percentage / 100);
};
